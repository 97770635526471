<template>
    <nav class="bg-v3-white-100 dark:bg-v3-gray-900">
        <div class="max-w-full mx-auto px-2 sm:px-6 lg:px-8 h-20">
            <div class="relative flex justify-between h-full">
                <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div class="flex-shrink-0 flex items-center text-xl w-full">
                      <div class="flex justify-between w-full items-center h-full max-h-full" >
                          <template v-if="!$slots.lottieHeaderDark && !$slots.lottieHeaderLight" class="cursor-pointer" @click="goToDashboard">
<!--                              <div class="inline-flex cursor-pointer" @click="goToDashboard">-->
<!--                                    <div v-if="logo" style="height: 80px; max-height: 80px" class="flex items-center">-->
<!--                                      <a href="/" ><img height="80" style="max-height:80px" :src="logo"/></a>-->
<!--                                    </div>-->
<!--                                      <div v-else class="" style="height: 80px" >-->
<!--                                          <img v-if="true && mode === 'dark' && mode !== null" src="../../../../images/logo-header-dark.png" class="py-4 opacity-80" style="height: 80px">-->
<!--                                          <img v-else-if="true && (mode === 'light' || mode === null)" src="../../../../images/logo-header-light.png" class="py-4 opacity-80" style="height: 80px">-->
<!--                                           <span v-else-if="false" class="text-red-500 text-3xl font-bold py-4 flex items-center" style="height: 80px">TEST</span>-->
<!--                                    </div>-->
<!--                                  <div class="flex items-center text-left text-4xl pl-2 text-v3-gray-500 dark:text-v3-gray-400">-->
<!--                                    <slot name="header"></slot>-->
<!--                                  </div>-->
<!--                              </div>-->
                          </template>
                          <div @click="goToDashboard" class="cursor-pointer">
                              <slot  name="lottieHeaderLight">
                              </slot>
                              <slot name="lottieHeaderDark"></slot>
                              </div>
                          <div>
                              <span v-click-outside="{
                                  handler: clickOutsideSearch,
                                  include
                                }" class="isolate inline-flex rounded-full shadow-sm bg-v3-gray-200 text-v3-gray-800 dark:bg-v3-gray-800 dark:text-v3-gray-200">
                                  <div v-if="showSearch">
                                      <div class="inline-flex h-11 rounded-full m-1 items-center bg-v3-white-100 dark:bg-v3-gray-900">
                                            <input id="search" @input="search" v-model="query" ref="search" class="rounded-full h-full bg-v3-white-100 text-v3-gray-800 dark:bg-v3-gray-900 dark:text-v3-gray-200 outline-none" style="text-indent: 10px;" placeholder="Search" autocomplete="off"/>
                                            <svg class="relative right-2 h-7 w-7 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"/></svg>

                                      </div>
                                      <div v-if="showSearch && query && query.length > 3" class="z-20 included max-h-screen-3/4 overflow-auto include-search absolute top-13 right-24 z-10 mt-2 w-64 origin-top-right rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-4 text-black bg-v3-gray-200 dark:bg-v3-gray-800 dark:text-white"  tabindex="-1">
                                          <div class="py-1" role="none">
                                              <div class="my-2">
                                                  <collapsable-header v-if="orderResources && orderResources.count() >0" :collapsable="true" :open="orderOpen" @toggleOpen="orderOpen = !orderOpen">
                                                      <template v-slot:title>Orders</template>
                                                  </collapsable-header>
                                                  <div v-if="orderResources && orderResources.count() >0" class="h-96" :class="{'hidden': !orderOpen || !orderResources,'h-full': orderResources.count() <=15}" style="max-height: 42rem;">

                                                      <LazyList v-if="orderResources" :key="query"
                                                                :data="orderResources.toArray()"
                                                                :itemsPerRender="15"
                                                      >
                                                      <template v-slot="{item}">
                                                        <div class="flex cursor-pointer hover:underline" @click="navigateToItem(item)">
                                                            {{item.entity.properties.get('item')}}
                                                        </div>
                                                      </template>
                                                  </LazyList>
                                                  </div>
                                              </div>
                                              <div class="my-2">
                                                  <collapsable-header v-if="rentalResources && rentalResources.count() >0" :collapsable="true" :open="rentalOpen" @toggleOpen="rentalOpen = !rentalOpen">
                                                      <template v-slot:title>Rentals</template>
                                                  </collapsable-header>
                                                  <div v-if="rentalResources && rentalResources.count() >0" class="h-96" :class="{'hidden': !rentalOpen || !rentalResources,'h-full': rentalResources.count() <=15}" style="max-height: 42rem;">

                                                      <LazyList v-if="rentalResources" :key="query"
                                                                :data="rentalResources.toArray()"
                                                                :itemsPerRender="15"
                                                      >
                                                      <template v-slot="{item}">
                                                        <div class="flex cursor-pointer hover:underline" @click="navigateToItem(item)">
                                                            {{item.entity.properties.get('item')}}
                                                        </div>
                                                      </template>
                                                  </LazyList>
                                                  </div>
                                              </div>

                                                  <SearchElement
                                                                 :open="basePeopleOpen"
                                                                 :resources="basePeopleResources"
                                                                 :query="query"
                                                                 title="Base - People"
                                                                 propertyKey="name"
                                                  />
                                                  <SearchElement
                                                      :open="projectsOpen"
                                                      :resources="projectsResources"
                                                      :query="query"
                                                      title="Base - Projects"
                                                      propertyKey="name"
                                                  />
                                                  <SearchElement
                                                      :open="leaveRequestsOpen"
                                                      :resources="leaveRequestResources"
                                                      :query="query"
                                                      title="Leave Requests"
                                                      propertyKey="name"
                                                  />
                                                  <SearchElement
                                                      :open="hqPeopleOpen"
                                                      :resources="hqPeopleResources"
                                                      :query="query"
                                                      title="HQ - People"
                                                      propertyKey="name"
                                                  />
                                                  <SearchElement
                                                      :open="hqProjectsOpen"
                                                      :resources="hqProjectsResources"
                                                      :query="query"
                                                      title="HQ - Projects"
                                                      propertyKey="name"
                                                  />
                                                  <SearchElement
                                                      :open="trackersOpen"
                                                      :resources="trackersResources"
                                                      :query="query"
                                                      title="Trackers"
                                                      propertyKey="name"
                                                  />
                                                  <SearchElement
                                                      :open="qasOpen"
                                                      :resources="qasResources"
                                                      :query="query"
                                                      title="Qas"
                                                      propertyKey="name"
                                                  />

                                                  <SearchElement
                                                      :open="paymentVoucherOpen"
                                                      :resources="paymentVoucherResources"
                                                      :query="query"
                                                      title="Payment Vouchers"
                                                      propertyKey="reference"
                                                  />
                                              <SearchElement
                                                  :open="ossOpen"
                                                  :resources="ossResources"
                                                  :query="query"
                                                  title="OnSite"
                                                  propertyKey="name"
                                              />

                                              <template v-if="(!orderResources || orderResources.count() === 0) &&
                                                (!rentalResources || rentalResources.count(0) === 0) &&
                                                (!paymentVoucherResources || paymentVoucherResources.count() === 0) &&
                                                (!basePeopleResources || basePeopleResources.count() === 0) &&
                                                (!hqPeopleResources || hqPeopleResources.count() === 0) &&
                                                (!projectsResources || projectsResources.count() === 0) &&
                                                (!trackersResources || trackersResources.count() === 0) &&
                                                (!hqProjectsResources || hqProjectsResources.count() === 0) &&
                                                (!leaveRequestResources || leaveRequestResources.count() === 0) &&
                                                (!ossResources || ossResources.count() === 0)
                                                ">
                                                  <div class="flex cursor-pointer">No search results found.</div>
                                              </template>
                                          </div>
                                      </div>
                                  </div>
                                  <div v-if="!showSearch" @click="showSearchClicked" class="cursor-pointer inline-flex h-11 w-11 rounded-full m-1 items-center bg-v3-white-100 dark:bg-v3-gray-900">
                                    <svg class="h-7 w-7 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"/></svg>
                                </div>
                                <div @click="showApplications = !showApplications" class="cursor-pointer inline-flex h-11 w-11 rounded-full m-1 items-center bg-v3-white-100 dark:bg-v3-gray-900">
                                    <svg class="h-7 w-7 mx-auto" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_607_2275)">
                                        <path d="M12 14C12 12.8954 12.8954 12 14 12H18.2C19.3046 12 20.2 12.8954 20.2 14V18.2C20.2 19.3046 19.3046 20.2 18.2 20.2H14C12.8954 20.2 12 19.3046 12 18.2V14Z" fill="#FFA629"/>
                                        <path d="M12.25 14C12.25 13.0335 13.0335 12.25 14 12.25H18.2C19.1665 12.25 19.95 13.0335 19.95 14V18.2C19.95 19.1665 19.1665 19.95 18.2 19.95H14C13.0335 19.95 12.25 19.1665 12.25 18.2V14Z" stroke="white" stroke-opacity="0.15" stroke-width="0.5"/>
                                        </g>
                                        <g filter="url(#filter1_d_607_2275)">
                                        <path d="M2 4C2 2.89543 2.89543 2 4 2H8C9.10457 2 10 2.89543 10 4V8C10 9.10457 9.10457 10 8 10H4C2.89543 10 2 9.10457 2 8V4Z" fill="#0D99FF"/>
                                        <path d="M2.25 4C2.25 3.0335 3.0335 2.25 4 2.25H8C8.9665 2.25 9.75 3.0335 9.75 4V8C9.75 8.9665 8.9665 9.75 8 9.75H4C3.0335 9.75 2.25 8.9665 2.25 8V4Z" stroke="white" stroke-opacity="0.15" stroke-width="0.5"/>
                                        </g>
                                        <g filter="url(#filter2_d_607_2275)">
                                        <path d="M12 4C12 2.89543 12.8954 2 14 2H18C19.1046 2 20 2.89543 20 4V8C20 9.10457 19.1046 10 18 10H14C12.8954 10 12 9.10457 12 8V4Z" fill="#14AE5C"/>
                                        <path d="M12.25 4C12.25 3.0335 13.0335 2.25 14 2.25H18C18.9665 2.25 19.75 3.0335 19.75 4V8C19.75 8.9665 18.9665 9.75 18 9.75H14C13.0335 9.75 12.25 8.9665 12.25 8V4Z" stroke="white" stroke-opacity="0.15" stroke-width="0.5"/>
                                        </g>
                                        <g filter="url(#filter3_d_607_2275)">
                                        <path d="M2 14C2 12.8954 2.89543 12 4 12H8C9.10457 12 10 12.8954 10 14V18C10 19.1046 9.10457 20 8 20H4C2.89543 20 2 19.1046 2 18V14Z" fill="#9747FF"/>
                                        <path d="M2.25 14C2.25 13.0335 3.0335 12.25 4 12.25H8C8.9665 12.25 9.75 13.0335 9.75 14V18C9.75 18.9665 8.9665 19.75 8 19.75H4C3.0335 19.75 2.25 18.9665 2.25 18V14Z" stroke="white" stroke-opacity="0.15" stroke-width="0.5"/>
                                        </g>
                                        <defs>
                                        <filter id="filter0_d_607_2275" x="10.72" y="10.72" width="11.5602" height="11.5602" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.4" dy="0.4"/>
                                        <feGaussianBlur stdDeviation="0.84"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0675 0 0 0 0 0.12105 0 0 0 0 0.225 0 0 0 0.05 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_607_2275"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_607_2275" result="shape"/>
                                        </filter>
                                        <filter id="filter1_d_607_2275" x="0.72" y="0.72" width="11.36" height="11.36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.4" dy="0.4"/>
                                        <feGaussianBlur stdDeviation="0.84"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0675 0 0 0 0 0.12105 0 0 0 0 0.225 0 0 0 0.05 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_607_2275"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_607_2275" result="shape"/>
                                        </filter>
                                        <filter id="filter2_d_607_2275" x="10.72" y="0.72" width="11.36" height="11.36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.4" dy="0.4"/>
                                        <feGaussianBlur stdDeviation="0.84"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0675 0 0 0 0 0.12105 0 0 0 0 0.225 0 0 0 0.05 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_607_2275"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_607_2275" result="shape"/>
                                        </filter>
                                        <filter id="filter3_d_607_2275" x="0.72" y="10.72" width="11.36" height="11.36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.4" dy="0.4"/>
                                        <feGaussianBlur stdDeviation="0.84"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0675 0 0 0 0 0.12105 0 0 0 0 0.225 0 0 0 0.05 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_607_2275"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_607_2275" result="shape"/>
                                        </filter>
                                        </defs>
                                        </svg>

<!--                                    <svg class="h-7 w-7 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 3h8v8H3zm10 0h8v8h-8zM3 13h8v8H3zm10 0h8v8h-8z"/></svg>-->
                                </div>
                                  <div v-if="user" @click.prevent="showAccountActionsClicked" class="inline-flex h-11 w-11 m-1 items-center bg-v3-white-100 dark:bg-v3-gray-900 rounded-full cursor-pointer">
                                      <img
                                          class="inline-block h-11 w-11 rounded-full my-4" v-if="user.profile_image" :src="'/storage/profile_images/' + user.profile_image">
                                      <img
                                          class="inline-block h-11 w-11 rounded-full my-4" v-else-if="user.oss_profile_image && user.oss_profile_image.includes('on-site-selfie')" :src="'/' + user.oss_profile_image.replace('public', 'storage')">
                                      <div v-else class="flex justify-center w-full">
                                          <svg class="h-7 w-7 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"/></svg>
                                      </div>

                                  </div>
                                <div v-else @click.prevent="showAccountActionsClicked" class="open-account cursor-pointer inline-flex h-11 w-11 rounded-full m-1 items-center bg-v3-base-500 dark:bg-v3-gray-900">
                                    <svg class="h-7 w-7 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"/></svg>
                                </div>

                              </span>



                              <div v-click-outside="closeAccountActions" v-if="showAccountActions"  class="absolute top-20 right-0 z-40 mt-2 w-56 origin-top-right rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-4 text-black bg-v3-gray-200 dark:bg-v3-gray-800 dark:text-white"  tabindex="-1">
                                  <div class="py-1" role="none">
                                        <div class="flex justify-end">
                                          <button @click="toggleMode" type="button" class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none shadow-inner bg-v3-base-500 dark:bg-v3-toggle-800" role="switch" aria-checked="false">
                                              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                                              <span class="pointer-events-none relative inline-block h-5 w-5 transform rounded-full shadow ring-0 transition ease-in-out bg-v3-toggle-button-200 dark:bg-v3-toggle-button-800" :class="{'translate-x-5 ': mode === 'dark','translate-x-0': mode !== 'dark'}">
                                                <!-- Enabled: "opacity-0 duration-100 ease-out", Not Enabled: "opacity-100 duration-200 ease-in" -->
                                                <span class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity" aria-hidden="true" :class="{'opacity-0  ease-out': mode === 'dark', 'opacity-100 ease-in': mode !== 'dark'}">
                                                  <svg class="h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m6.76 4.84l-1.8-1.79l-1.41 1.41l1.79 1.79zM4 10.5H1v2h3zm9-9.95h-2V3.5h2zm7.45 3.91l-1.41-1.41l-1.79 1.79l1.41 1.41zm-3.21 13.7l1.79 1.8l1.41-1.41l-1.8-1.79zM20 10.5v2h3v-2zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6m-1 16.95h2V19.5h-2zm-7.45-3.91l1.41 1.41l1.79-1.8l-1.41-1.41z"/></svg>

                                                </span>
                                              <!-- Enabled: "opacity-100 duration-200 ease-in", Not Enabled: "opacity-0 duration-100 ease-out" -->
                                                <span class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity" aria-hidden="true" :class="{'opacity-100 ease-in' :mode ==='dark', 'opacity-0 ease-out': mode !== 'dark'}">
                                                    <svg class="h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3a9 9 0 1 0 9 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 0 1-4.4 2.26a5.403 5.403 0 0 1-3.14-9.8c-.44-.06-.9-.1-1.36-.1"/></svg>
                                                </span>
                                              </span>
                                          </button>
                                        </div>
                                      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                      <div class="flex flex-col items-center text-center">
                                          <template v-if="user">
                                              <img
                                                  class="inline-block h-16 w-16 rounded-full my-4" v-if="user.profile_image" :src="'/storage/profile_images/' + user.profile_image">
                                              <img
                                                  class="inline-block h-16 w-16 rounded-full my-4" v-else-if="user.oss_profile_image && user.oss_profile_image.includes('on-site-selfie')" :src="'/' + user.oss_profile_image.replace('public', 'storage')">
                                              <div v-else class="flex justify-center">
                                                <span
                                                class="inline-flex items-center justify-center h-16 w-16 rounded-full my-4 bg-gray-400">
                                                    <span class="text-md font-medium leading-none text-white">{{ initials }}</span>
                                                </span>
                                              </div>
                                            {{user.name}}

                                          </template>
                                      </div>
                                          <div class="text-center rounded-full m-4 text-sm py-1 bg-v3-gray-300 text-v3-gray-600 dark:bg-v3-gray-600 dark:text-v3-gray-300">
                                              <a class="block w-full h-full" :href="getLinkHref('current-user')"> My Account</a>
                                          </div>

                                      <div class="py-2 text-sm pl-4 text-v3-gray-500 dark:text-v3-gray-400">
                                          Manage
                                      </div>
                                      <div class="grid grid-cols-4 text-v3-gray-600 dark:text-v3-gray-400">
                                          <a href="/notifications" class="flex items-center mx-auto">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 22c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2m6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1z"/></svg>
                                          </a>
                                          <a href="/notifications" class="block px-4 py-2 text-sm col-span-3" role="menuitem" tabindex="-1" id="menu-item-3">Notifications</a>
                                          <a v-if="canAccessHelpMenu()" :href="getLinkHref('cms-help-hub')" class="flex items-center mx-auto">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44c.91-1.29.4-3.7-2.18-3.7c-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41c.7 1.15 1.11 3.3.03 4.9c-1.2 1.77-2.35 2.31-2.97 3.45c-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15M14 20c0 1.1-.9 2-2 2s-2-.9-2-2s.9-2 2-2s2 .9 2 2"/></svg>
                                          </a>
                                          <a v-if="canAccessHelpMenu()" :href="getLinkHref('cms-help-hub')" class="block px-4 py-2 text-sm col-span-3" role="menuitem" tabindex="-1" id="menu-item-3">CMS Help Hub</a>

                                          <div @click="signOut" class="cursor-pointer flex items-center mx-auto">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 2h9a2 2 0 0 1 2 2v2h-2V4H6v16h9v-2h2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2"/><path fill="currentColor" d="M16.09 15.59L17.5 17l5-5l-5-5l-1.41 1.41L18.67 11H9v2h9.67z"/></svg>
                                          </div>
                                          <a @click.prevent="signOut" class="cursor-pointer block px-4 py-2 text-sm col-span-3" role="menuitem" tabindex="-1" id="menu-item-3">Sign Out</a>
                                      </div>
                                  </div>
                              </div>


                              <div v-click-outside="closeApplications" v-if="showApplications"  class="fixed top-20 right-0 z-40 mt-2 origin-top-right rounded-2xl focus:outline-none text-black dark:text-white h-full w-full overflow-hidden"  tabindex="-1">
                                  <div class="flex justify-between rounded-lg w-full h-full overflow-hidden px-2">
<!--                                      grid grid-rows-3 grid-flow-col gap-4 -->

                                      <div class="grid grid-cols-7 grid-rows-3 gap-2 h-full w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 bg-opacity-90 overflow-hidden" style="height:calc(100% - 112px)">
                                          <div class="bg-v3-gray-50 bg-opacity-10 w-full rounded-xl overflow-auto zoom-container text-base">
                                              <date-picker v-model="date" :highlighted="highlighted" :inline="true" wrapper-class="calendar-wrapper" calendar-class="calendar-main" :bootstrap-styling="true"></date-picker>
                                          </div>
                                          <div class="row-span-2 col-start-1 row-start-2 bg-v3-gray-50 bg-opacity-10 w-full rounded-xl overflow-auto zoom-container text-base overflow-hidden">
                                              <notifications :notificationsFromParent="notifications" :notificationsTotalFromParent="notificationsTotal"></notifications>
                                          </div>
                                          <div class="col-span-6 row-span-3 col-start-2 row-start-1 bg-v3-gray-50 bg-opacity-10 rounded-xl w-full h-full grid grid-cols-1 grid-rows-3">
                                              <div class="p-12 rounded-lg text-white/50 justify-between w-full h-fit grid grid-cols-6 gap-4 gap-y-8 font-bold row-span-2" style="height:fit-content">
                                                  <div title="Hub" @click="goToHrPage" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3381_101681)">
                                                                  <path opacity="0.5" d="M42.2898 70.0124C61.6334 70.0124 77.296 54.3306 77.296 35.0185C77.296 15.7065 61.6334 0 42.2898 0H0.267578V140L63.2639 77.0259C57.4089 72.6301 50.1705 69.9877 42.2898 69.9877V70.0124Z" fill="#9747FF"/>
                                                                  <path opacity="0.5" d="M98.2456 42.0074V42.0568L63.2641 77.0259C71.7624 83.4221 77.2962 93.5474 77.2962 105.006C77.2962 124.343 61.6089 140 42.29 140H59.682L98.2456 101.45V140H140.268V0L98.2456 42.0074Z" fill="#9747FF"/>
                                                                  <path d="M77.296 105.007C77.296 93.5478 71.7622 83.4226 63.2639 77.0264L0.267578 140H42.2898C61.6334 140 77.296 124.319 77.296 105.007Z" fill="#9747FF"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3381_101681">
                                                                      <rect width="140" height="140" fill="white" transform="translate(0.267578)"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>

                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">Base</span>
                                                  </div>

                                                  <div title="Procure" v-if="hasLink('orders')" @click="goToLinkHref('orders')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3022_102324)">
                                                                  <path opacity="0.5" d="M26.7578 0.987829C24.955 0.358088 23.0288 0 21.0037 0H0V35.0062C0 18.5094 11.4094 4.69219 26.7578 0.987829Z" fill="#14AE5C"/>
                                                                  <path opacity="0.5" d="M0 35.0063V70.0125H21.0037V67.0861C8.6435 61.6777 0 49.3546 0 35.0063Z" fill="#14AE5C"/>
                                                                  <path opacity="0.5" d="M21.0039 35.0064C29.3387 35.0064 36.3029 29.1658 38.0686 21.362C37.0808 21.1397 36.0559 21.0039 35.0064 21.0039C27.2766 21.0039 21.0039 27.2766 21.0039 35.0064Z" fill="#14AE5C"/>
                                                                  <path opacity="0.5" d="M35.0064 49.0088C27.2766 49.0088 21.0039 42.7361 21.0039 35.0063V67.0861C25.2886 68.963 30.0302 70.0125 35.0064 70.0125C50.6758 70.0125 63.9374 59.7144 68.4073 45.5144H44.2549C41.7853 47.6876 38.5626 49.0088 35.0187 49.0088H35.0064Z" fill="#14AE5C"/>
                                                                  <path opacity="0.5" d="M38.5006 17.4969C38.5006 18.8181 38.3401 20.1023 38.0561 21.3494C40.3898 21.8804 42.5013 22.967 44.23 24.4981H68.3824C63.9372 10.2981 50.6756 0 35.0062 0C32.1662 0 29.4003 0.34574 26.7578 0.987829C33.5985 3.37096 38.513 9.85359 38.513 17.4969H38.5006Z" fill="#14AE5C"/>
                                                                  <path d="M35.0062 21.0035C36.0557 21.0035 37.0806 21.1394 38.0684 21.3616C38.3524 20.1145 38.513 18.8303 38.513 17.5091C38.513 9.86576 33.5985 3.37079 26.7578 1C11.4094 4.69201 0 18.5093 0 35.006C0 49.3542 8.6435 61.6897 21.0037 67.0857V35.006C21.0037 27.2762 27.2764 21.0035 35.0062 21.0035Z" fill="#14AE5C"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3022_102324">
                                                                      <rect width="68.3948" height="70" fill="white"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>
                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">Procure</span>

                                                  </div>
                                                  <div v-if="hasLink('quality-assessments') || hasLink('qa-trackers') || hasLink('qa-categories') || hasLink('qa-pre-checks')" @click="goToQaPage" title="QA" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="74" height="70" viewBox="0 0 74 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3022_102358)">
                                                                  <path opacity="0.5" d="M21.0407 35.8458C21.0284 35.5618 21.0037 35.2778 21.0037 34.9938C21.0037 27.2641 27.2764 20.9914 35.0062 20.9914C39.8342 20.9914 44.0942 23.4362 46.6132 27.1529L52.75 21.0161V20.9914L61.5541 12.1873C55.1208 4.72923 45.613 0 35.0062 0C15.6694 0 0 15.6694 0 35.0062C0 45.6253 4.74158 55.1455 12.212 61.5541L37.9203 35.8458H21.0531H21.0407Z" fill="#FFA629"/>
                                                                  <path opacity="0.5" d="M33.4997 69.9631C33.9936 69.9878 34.4998 70.0001 34.9937 70.0001C37.7844 70.0001 40.4885 69.6667 43.0816 69.0493L44.0324 70.0001H52.7253V50.7251L33.4873 69.9631H33.4997Z" fill="#FFA629"/>
                                                                  <path opacity="0.5" d="M3.76562 70.0001H33.4622L33.4993 69.9631C25.3744 69.6174 17.978 66.5057 12.2116 61.5542L3.76562 70.0001Z" fill="#FFA629"/>
                                                                  <path opacity="0.5" d="M61.542 12.1997C66.8145 18.3242 70.0003 26.2886 70.0003 35.0062C70.0003 43.7238 66.8145 51.6881 61.542 57.8127L73.7417 70.0124V0L61.542 12.1997Z" fill="#FFA629"/>
                                                                  <path opacity="0.5" d="M39.5875 35.8457L46.5887 42.847C48.1075 40.612 48.9966 37.9078 48.9966 34.9937C48.9966 32.0796 48.1075 29.3878 46.6011 27.1528L37.9082 35.8457H39.5752H39.5875Z" fill="#FFA629"/>
                                                                  <path opacity="0.5" d="M69.9999 35.0062C69.9999 26.2886 66.8141 18.3242 61.5416 12.1997L52.7376 21.0037V21.0284L46.6007 27.1653C48.1195 29.4003 48.9962 32.1045 48.9962 35.0062C48.9962 37.9079 48.1071 40.6245 46.5883 42.8594L39.5871 35.8582H37.9202L12.2119 61.5664C17.9784 66.5179 25.3871 69.6296 33.4996 69.9753L52.7376 50.7374V70.0124H73.7413L61.5416 57.8127C66.8141 51.6882 69.9999 43.7238 69.9999 35.0062Z" fill="#FFA629"/>
                                                                  <path d="M69.9999 35.0062C69.9999 26.2886 66.8141 18.3242 61.5416 12.1997L52.7376 21.0037V21.0284L46.6007 27.1653C48.1195 29.4003 48.9962 32.1045 48.9962 35.0062C48.9962 37.9079 48.1071 40.6245 46.5883 42.8594L39.5871 35.8582H37.9202L12.2119 61.5664C17.9784 66.5179 25.3871 69.6296 33.4996 69.9753L52.7376 50.7374V70.0124H73.7413L61.5416 57.8127C66.8141 51.6882 69.9999 43.7238 69.9999 35.0062Z" fill="#FFA629"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3022_102358">
                                                                      <rect width="73.7414" height="70" fill="white"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>
                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">TraQA</span>

                                                  </div>
                                                  <div title="Voucher" v-if="hasLink('payment-vouchers')" @click="goToLinkHref('payment-vouchers')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3022_102369)">
                                                                  <path opacity="0.5" d="M39.9332 0.358088C38.3156 0.135826 36.6733 0 34.994 0C30.0178 0 25.2886 1.04957 21.0039 2.91409V19.275L39.9332 0.358088Z" fill="#FF006E"/>
                                                                  <path opacity="0.5" d="M59.7386 10.2487L44.8841 25.1031C47.4278 27.6345 48.996 31.1413 48.996 35.0061C48.996 42.7359 42.7233 49.0086 34.9935 49.0086C31.1286 49.0086 27.6218 47.4404 25.0905 44.8968L21.0157 48.9716V48.9963L10.2607 59.7512C16.5952 66.0857 25.3375 70 35.0058 70C54.3426 70 70.012 54.3305 70.012 34.9938C70.012 25.3254 66.0854 16.5708 59.7509 10.2363L59.7386 10.2487Z" fill="#FF006E"/>
                                                                  <path opacity="0.5" d="M0 35.0061C0 35.1913 0 35.3641 0.0123479 35.5494V34.4751C0.0123479 34.6603 0 34.8332 0 35.0184V35.0061Z" fill="#FF006E"/>
                                                                  <path opacity="0.5" d="M21.0039 35.0064C21.0039 38.8713 22.5721 42.3657 25.1034 44.897L44.897 25.1034C42.3657 22.5721 38.8713 21.0039 35.0064 21.0039C27.2766 21.0039 21.0039 27.2766 21.0039 35.0064Z" fill="#FF006E"/>
                                                                  <path opacity="0.5" d="M69.9873 0H40.2907L39.9326 0.358088C47.613 1.4447 54.5031 5.01323 59.7386 10.2487L69.9873 0Z" fill="#FF006E"/>
                                                                  <path opacity="0.5" d="M21.0164 2.91409V0H0.0126953V34.4629C0.222609 20.3369 8.80437 8.24837 21.0164 2.91409Z" fill="#FF006E"/>
                                                                  <path opacity="0.5" d="M0.0126953 35.5371V70L10.2614 59.7513C4.0381 53.5403 0.16087 44.9956 0.0126953 35.5371Z" fill="#FF006E"/>
                                                                  <path d="M21.0164 48.9714L25.0912 44.8966C22.5599 42.3653 20.9917 38.8709 20.9917 35.006C20.9917 27.2762 27.2644 21.0035 34.9942 21.0035C38.8591 21.0035 42.3535 22.5717 44.8848 25.103L59.7393 10.2485C54.5161 5.01305 47.626 1.43217 39.9333 0.35791L21.0164 19.2748V2.91392C8.80437 8.24819 0.222609 20.3367 0.0126953 34.4627V35.537C0.16087 44.9954 4.0381 53.5401 10.2614 59.7511L21.0164 48.9961V48.9714Z" fill="#FF006E"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3022_102369">
                                                                      <rect width="70" height="70" fill="white"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>
                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">Voucher</span>

                                                  </div>
                                                  <div title="HQ" v-if="hasLink('invoices') || hasLink('invoices-in') || hasLink('credit-notes') || hasLink('sages') || hasLink('clients')" @click="goToHqPage" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12 fill-black dark:fill-white" width="74" height="71" viewBox="0 0 74 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path opacity="0.5" d="M25.7575 24.498C22.8434 27.0664 20.9912 30.8078 20.9912 34.9937C20.9912 35.2777 21.0159 35.5617 21.0283 35.8457H39.5747L46.576 42.8469C48.0948 40.612 48.9838 37.9078 48.9838 34.9937C48.9838 27.2639 42.7111 20.9912 34.9813 20.9912C33.8577 20.9912 32.7711 21.1394 31.7215 21.3863V24.4857H25.7451L25.7575 24.498Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M31.7344 69.9999H34.9942C33.8952 69.9999 32.8086 69.9381 31.7344 69.8394V69.9999Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M31.7344 0V0.160522C32.8086 0.0617393 33.8952 0 34.9942 0H31.7344Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M52.7374 0H35.0059C41.4761 0 47.5389 1.76574 52.7374 4.82801V0Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M44.0445 70.0001L43.0937 69.0493C40.5007 69.6667 37.7965 70.0001 35.0059 70.0001H52.7374H44.0445Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M0.234375 39.019V69.9998H21.2381V67.1845C9.92744 62.3441 1.69142 51.7126 0.234375 39.019Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M21.2381 2.81531V0H0.234375V30.9808C1.69142 18.2872 9.92744 7.65567 21.2381 2.81531Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M0 35.0062C0 36.3645 0.086435 37.7104 0.234609 39.0316V30.9932C0.086435 32.3144 0 33.648 0 35.0186V35.0062Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M25.7576 24.4983C27.4369 23.0165 29.4743 21.9299 31.734 21.399V0.160645C28.042 0.506385 24.5105 1.42013 21.2383 2.81543V24.4983H25.7576Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M21.2383 45.5021V67.1849C24.5105 68.5926 28.042 69.494 31.734 69.8397V45.4897H21.2383V45.5021Z" fill="currentColor"/>
                                                              <path opacity="0.5" d="M52.7373 45.502V70.0001H73.741L61.5413 57.8004C66.8139 51.6759 69.9996 43.7115 69.9996 34.9939C69.9996 22.1398 63.0601 10.9033 52.7373 4.82812V45.502Z" fill="currentColor"/>
                                                              <path d="M44.0446 70H52.7375V4.82801C47.539 1.76574 41.4762 0 35.0059 0C33.907 0 32.8204 0.0617393 31.7461 0.160522V21.3988C32.7957 21.1519 33.8823 21.0037 35.0059 21.0037C42.7357 21.0037 49.0084 27.2764 49.0084 35.0062C49.0084 37.9203 48.1194 40.6244 46.6006 42.8594L39.5993 35.8582H21.0529C21.0405 35.5742 21.0158 35.2902 21.0158 35.0062C21.0158 30.8203 22.868 27.0665 25.7821 24.5105H21.2628V2.81531C9.92744 7.65567 1.69142 18.2872 0.234375 30.9808V39.0192C1.69142 51.7128 9.92744 62.3443 21.2381 67.1847V45.5019H31.7338V69.8518C32.808 69.9506 33.8946 70.0124 34.9936 70.0124C37.7842 70.0124 40.4884 69.679 43.0814 69.0616L44.0322 70.0124L44.0446 70Z" fill="currentColor"/>
                                                          </svg>
                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">HQ</span>

                                                  </div>

                                                  <div title="costing" v-if="hasLink('costing')" @click="goToLinkHref('costing')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">

                                                          <svg class="h-12 w-12" width="82" height="70" viewBox="0 0 82 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3022_102395)">
                                                                  <path opacity="0.5" d="M40.5752 22.177C41.9211 22.7696 43.1559 23.5476 44.2425 24.4983H55.4173C52.9477 22.3251 49.725 21.0039 46.1811 21.0039C44.1931 21.0039 42.2915 21.4237 40.5752 22.177Z" fill="#8DD000"/>
                                                                  <path opacity="0.5" d="M11.1748 35.0062C11.1748 17.571 23.9055 3.12401 40.5874 0.444523C38.7723 0.148174 36.9077 0 35.0062 0C15.6694 0 0 15.6694 0 35.0062C0 54.3429 15.6694 70.0124 35.0062 70.0124C36.9077 70.0124 38.7723 69.8518 40.5874 69.5678C23.9178 66.8883 11.1748 52.4413 11.1748 35.0185V35.0062Z" fill="#8DD000"/>
                                                                  <path opacity="0.5" d="M40.5625 47.8234C42.2789 48.5766 44.1681 48.9964 46.1684 48.9964C49.7123 48.9964 52.9351 47.6628 55.4046 45.502H44.2298C43.1432 46.4651 41.8961 47.243 40.5625 47.8234Z" fill="#8DD000"/>
                                                                  <path opacity="0.5" d="M32.166 35.0064C32.166 29.2646 35.6234 24.3378 40.5626 22.177C38.8586 21.4361 36.9817 21.0039 34.9937 21.0039C27.2639 21.0039 20.9912 27.2766 20.9912 35.0064C20.9912 42.7361 27.2639 49.0088 34.9937 49.0088C36.9817 49.0088 38.8586 48.5767 40.5626 47.8358C35.6234 45.6749 32.166 40.7481 32.166 35.0064Z" fill="#8DD000"/>
                                                                  <path opacity="0.5" d="M46.1691 0C44.2676 0 42.403 0.160522 40.5879 0.444523C53.7631 2.55601 64.481 12.0145 68.4076 24.4981H55.4177C58.3318 27.0665 60.1839 30.8079 60.1839 34.9938C60.1839 39.1797 58.3318 42.9335 55.4177 45.4895H68.4076C64.4933 57.9732 53.7631 67.4316 40.5879 69.5431C42.403 69.8395 44.2799 69.9877 46.1815 69.9877C65.5182 69.9877 81.1876 54.3182 81.1876 34.9815C81.1876 15.6447 65.5059 0 46.1691 0Z" fill="#8DD000"/>
                                                                  <path d="M68.3948 45.5017H55.4048C52.9353 47.6749 49.7125 48.9961 46.1686 48.9961C44.1806 48.9961 42.2791 48.5763 40.5627 47.8231C38.8587 48.5639 36.9818 48.9961 34.9938 48.9961C27.2641 48.9961 20.9914 42.7234 20.9914 34.9936C20.9914 27.2639 27.2641 20.9912 34.9938 20.9912C36.9818 20.9912 38.8587 21.4233 40.5627 22.1642C42.2791 21.411 44.1683 20.9912 46.1686 20.9912C49.7125 20.9912 52.9353 22.3247 55.4048 24.4856H68.3948C64.4805 12.0266 53.7626 2.56817 40.5874 0.444336C23.9054 3.12382 11.1748 17.5708 11.1748 35.006C11.1748 52.4412 23.9178 66.8758 40.5874 69.5553C53.7626 67.4438 64.4805 57.9853 68.4071 45.5017H68.3948Z" fill="#8DD000"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3022_102395">
                                                                      <rect width="81.1748" height="70" fill="white"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>
                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">Costing</span>

                                                  </div>

                                                  <div v-if="hasLink('oss-daily-report')" @click="goToLinkHref('oss-daily-report')" title="OnSite" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="w-12 h-12" width="82" height="70" viewBox="0 0 82 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3022_102338)">
                                                                  <path opacity="0.5" d="M35.3025 48.9841C35.2037 48.9841 35.105 48.9964 34.9938 48.9964C27.2641 48.9964 20.9914 42.7237 20.9914 34.994C20.9914 27.2642 27.2641 20.9915 34.9938 20.9915C35.0926 20.9915 35.1914 21.0038 35.3025 21.0038C36.0804 12.8913 41.0566 6.01355 48.0085 2.49441C43.9831 0.876839 39.5996 -0.012207 34.9938 -0.012207C15.6694 0.000140825 0 15.6696 0 35.0063C0 54.3431 15.6694 70.0125 35.0062 70.0125C39.5996 70.0125 43.9954 69.1111 48.0208 67.5059C41.069 63.9867 36.0928 57.109 35.3149 48.9964L35.3025 48.9841Z" fill="#0D99FF"/>
                                                                  <path opacity="0.5" d="M42.6367 46.7364H58.2815C58.2815 46.934 58.6519 46.9463 58.6519 46.7364C58.6519 46.6376 58.5655 46.5512 58.4667 46.5512C54.0461 46.5512 49.922 45.2917 46.3905 43.1309C45.3779 44.5509 44.1061 45.7733 42.6367 46.7364Z" fill="#0D99FF"/>
                                                                  <path opacity="0.5" d="M58.2822 23.2759C58.2822 23.3747 58.3687 23.4611 58.4674 23.4611C62.1841 23.4611 65.6909 24.3625 68.8149 25.9183C68.5803 25.0169 68.2963 24.1402 67.9876 23.2759H58.6527C58.6527 23.066 58.2822 23.0783 58.2822 23.2759Z" fill="#0D99FF"/>
                                                                  <path opacity="0.5" d="M35.3022 48.9838C38.0064 48.922 40.513 48.1071 42.6245 46.7241H35.1787C35.1787 47.4897 35.2158 48.2429 35.2898 48.9838H35.3022Z" fill="#0D99FF"/>
                                                                  <path opacity="0.5" d="M67.987 23.2757H81.7425C81.7425 10.4463 71.3086 0 58.4668 0C54.7007 0 51.1569 0.913741 48.0205 2.50661C57.3061 6.22332 64.6284 13.8049 67.987 23.2757Z" fill="#0D99FF"/>
                                                                  <path opacity="0.5" d="M68.8026 25.918C69.5805 28.8197 70.0003 31.8573 70.0003 35.006C70.0003 49.737 60.9 62.3318 48.0088 67.5056C51.1575 69.0984 54.7013 70.0122 58.4551 70.0122C71.2845 70.0122 81.7308 59.5782 81.7308 46.7365C81.7308 37.6237 76.4459 29.7335 68.8026 25.918Z" fill="#0D99FF"/>
                                                                  <path opacity="0.5" d="M46.3913 43.1311C48.0335 40.8344 49.009 38.0438 49.009 35.0062C49.009 27.3753 42.8968 21.189 35.3152 21.0161C35.2411 21.757 35.2041 22.5102 35.2041 23.2758C35.2041 31.6847 39.6987 39.044 46.4036 43.1311H46.3913Z" fill="#0D99FF"/>
                                                                  <path d="M70.0002 35.0064C70.0002 31.8577 69.5804 28.8201 68.8025 25.9184C65.6785 24.3625 62.184 23.4611 58.455 23.4611C58.3562 23.4611 58.2697 23.3747 58.2697 23.2759C58.2697 23.0784 58.6402 23.066 58.6402 23.2759H67.9752C64.6042 13.8175 57.2943 6.23589 48.0087 2.50684C41.0568 6.02598 36.0806 12.9037 35.3027 21.0163C42.8967 21.1768 48.9965 27.3754 48.9965 35.0064C48.9965 38.044 48.021 40.8346 46.3788 43.1313C49.9103 45.2798 54.0344 46.5516 58.455 46.5516C58.5537 46.5516 58.6402 46.6381 58.6402 46.7369C58.6402 46.9468 58.2697 46.9344 58.2697 46.7369H42.625C40.5135 48.1198 37.9946 48.9348 35.3027 48.9965C36.0806 57.1091 41.0568 63.9868 48.0087 67.506C60.8998 62.3446 70.0002 49.7497 70.0002 35.0064Z" fill="#0D99FF"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3022_102338">
                                                                      <rect width="81.7305" height="70" fill="white"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>

                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">OnSite</span>

                                                  </div>

                                                  <div title="SlipSend" v-if="hasLink('pay-n-send')" @click="goToLinkHref('pay-n-send')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_4347_133031)">
                                                                  <path opacity="0.5" d="M0.124023 93.4492C0.124023 94.9792 0.194023 96.4892 0.344023 97.9692H0.364023C5.77402 97.8492 10.784 96.2192 15.004 93.4492H0.124023Z" fill="#F13C27"/>
                                                                  <path opacity="0.5" d="M70.434 6.52C63.474 2.39 55.364 0 46.704 0C39.174 0 32.084 1.83 25.814 5.01C36.314 9.21 45.544 15.88 52.814 24.31C56.964 16.9 63.064 10.74 70.434 6.52Z" fill="#F13C27"/>
                                                                  <path opacity="0.5" d="M47.134 98.3495C52.514 98.2295 57.554 96.5995 61.774 93.8295H93.064C93.064 93.9395 93.124 94.0195 93.204 94.0695C93.204 93.8695 93.224 93.6795 93.224 93.4795C93.224 93.4795 93.224 93.4695 93.224 93.4595C84.464 93.4195 76.294 90.8895 69.284 86.6195C69.284 86.6195 69.294 86.5995 69.304 86.5995C55.924 78.4295 46.954 63.7295 46.944 46.9395C46.854 46.9395 46.764 46.9395 46.684 46.9395C46.484 46.9395 46.314 46.7695 46.314 46.5695C46.314 46.2595 46.764 46.1795 46.964 46.3595C46.984 45.0195 47.044 43.6995 47.174 42.3995H47.154C47.774 35.8995 49.774 29.7995 52.804 24.3595C45.534 15.9295 36.304 9.23953 25.804 5.01953C11.884 12.0495 1.92401 25.8095 0.374011 42.0295H0.394011C0.244011 43.5095 0.174011 45.0195 0.174011 46.5495C0.174011 63.3495 9.14401 78.0595 22.534 86.2395C22.534 86.2395 22.524 86.2595 22.514 86.2595C29.574 90.5595 37.824 93.0995 46.664 93.0995C46.864 93.0995 47.034 93.2695 47.034 93.4695C47.034 93.8895 46.294 93.8695 46.294 93.4695H15.014C10.794 96.2395 5.75401 97.8695 0.374011 97.9895C1.93401 114.21 11.884 127.97 25.784 135.01C32.084 138.19 39.164 140.02 46.674 140.02C55.084 140.02 62.984 137.77 69.794 133.84C57.344 126.45 48.584 113.47 47.134 98.3395V98.3495ZM47.114 98.3195C46.964 96.8395 46.894 95.3295 46.894 93.7995H61.784C57.564 96.5695 52.544 98.1995 47.144 98.3195H47.124H47.114Z" fill="#F13C27"/>
                                                                  <path d="M61.244 46.5509C57.964 44.5209 54.244 43.1609 50.254 42.6309C54.244 43.1609 57.964 44.5309 61.244 46.5509Z" fill="#F13C27"/>
                                                                  <path d="M46.884 93.8008C46.884 95.3308 46.954 96.8408 47.104 98.3208H47.124C52.534 98.2008 57.544 96.5708 61.764 93.8008H46.874H46.884Z" fill="#F13C27"/>
                                                                  <path opacity="0.5" d="M112.504 46.8996H140.014C140.014 21.2396 119.144 0.349609 93.464 0.349609C85.934 0.349609 78.844 2.17961 72.574 5.35961C91.144 12.7896 105.794 27.9596 112.504 46.8996Z" fill="#F13C27"/>
                                                                  <path opacity="0.5" d="M114.134 52.1909C115.694 57.9909 116.534 64.0709 116.534 70.3709C116.534 71.3409 116.514 72.3009 116.474 73.2709C116.514 72.3109 116.534 71.3509 116.534 70.3809C116.534 64.0809 115.694 58.0109 114.134 52.2009C107.884 49.0909 100.894 47.2909 93.434 47.2909C93.234 47.2909 93.064 47.1209 93.064 46.9209C93.064 46.5209 93.804 46.5009 93.804 46.9209H112.474C105.734 28.0009 91.114 12.8409 72.544 5.38086C71.824 5.74086 71.124 6.14086 70.424 6.54086C84.074 14.6609 93.244 29.5709 93.244 46.5709H65.734C62.814 38.3309 58.394 30.8109 52.804 24.3309C52.804 24.3409 52.794 24.3509 52.784 24.3709C58.364 30.8509 62.784 38.3509 65.714 46.5709H61.244C61.664 46.8309 62.074 47.0909 62.484 47.3709C62.084 47.0909 61.664 46.8209 61.244 46.5709H47.044C47.044 46.4809 47.004 46.4109 46.954 46.3609C46.954 46.5509 46.944 46.7309 46.944 46.9209C46.944 46.9209 46.944 46.9409 46.944 46.9509C54.304 46.9909 61.204 48.7809 67.374 51.8609C68.934 57.6609 69.774 63.7409 69.774 70.0409C69.774 71.0109 69.754 71.9709 69.714 72.9309C69.754 71.9709 69.774 71.0009 69.774 70.0309C69.774 63.7309 68.934 57.6609 67.374 51.8509C82.654 59.4809 93.224 75.2509 93.234 93.4709C93.304 93.4709 93.374 93.4709 93.444 93.4709C93.644 93.4709 93.814 93.6409 93.814 93.8409C93.814 94.1409 93.434 94.2109 93.214 94.0809C93.004 111.091 83.624 125.921 69.804 133.861C70.704 134.391 71.614 134.901 72.554 135.381C78.854 138.561 85.934 140.391 93.444 140.391C119.104 140.391 139.994 119.521 139.994 93.8409C139.994 75.6109 129.424 59.8309 114.134 52.2009V52.1909Z" fill="#F13C27"/>
                                                                  <path d="M93.254 46.5495C93.254 29.5495 84.084 14.6495 70.434 6.51953C63.064 10.7395 56.954 16.8995 52.814 24.3095C58.404 30.7895 62.824 38.3095 65.744 46.5495H93.254Z" fill="#F13C27"/>
                                                                  <path d="M93.214 94.0603C93.134 94.0103 93.074 93.9303 93.074 93.8203H61.784C57.564 96.5903 52.524 98.2203 47.144 98.3403C48.594 113.47 57.354 126.45 69.804 133.84C83.624 125.89 93.004 111.07 93.214 94.0603Z" fill="#F13C27"/>
                                                                  <path d="M69.304 86.5897C69.304 86.5897 69.294 86.6097 69.284 86.6097C76.294 90.8697 84.464 93.4097 93.224 93.4497C93.224 75.2297 82.644 59.4597 67.364 51.8297C68.924 57.6297 69.764 63.7097 69.764 70.0097C69.764 70.9797 69.744 71.9397 69.704 72.9097C69.744 71.9497 69.764 70.9897 69.764 70.0197C69.764 63.7197 68.924 57.6497 67.364 51.8397C61.194 48.7697 54.294 46.9797 46.934 46.9297C46.934 63.7197 55.914 78.4197 69.294 86.5897H69.304Z" fill="#F13C27"/>
                                                                  <path d="M47.164 42.3894C47.034 43.6894 46.974 45.0094 46.954 46.3494C47.004 46.3994 47.044 46.4694 47.044 46.5594H61.244C57.964 44.5394 54.244 43.1694 50.254 42.6394C54.244 43.1694 57.974 44.5394 61.244 46.5594H65.714C62.784 38.3394 58.364 30.8294 52.784 24.3594C49.744 29.7994 47.754 35.8894 47.134 42.3994H47.154L47.164 42.3894Z" fill="#F13C27"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_4347_133031">
                                                                      <rect width="139.89" height="140.38" fill="white" transform="translate(0.124023)"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>


                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">SlipSend</span>
                                                  </div>
                                                  <div title="Batch Invoice" v-if="hasLink('batch-invoice')" @click="goToLinkHref('batch-invoice')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="49" height="70" viewBox="0 0 49 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_3022_102332)">
                                                                  <path opacity="0.5" d="M21.0037 49.0086V35.0062V21.0037H14.0025V0H0V70H14.0025V49.0086H21.0037Z" fill="#05DCB2"/>
                                                                  <path opacity="0.5" d="M49.0088 21.0037V0H21.0039C30.6723 0 38.5008 7.84089 38.5008 17.4969C38.5008 27.1529 30.6723 34.9938 21.0039 34.9938C30.6599 34.9938 38.5008 42.8347 38.5008 52.4907C38.5008 62.1468 30.6599 69.9877 21.0039 69.9877H49.0088V48.9839H42.0076V20.979H49.0088V21.0037Z" fill="#05DCB2"/>
                                                                  <path d="M21.0035 21.0037V35.0062C30.6596 35.0062 38.5005 27.1653 38.5005 17.5093C38.5005 7.85324 30.6719 0 21.0035 0H14.0146V21.0037H21.0159H21.0035Z" fill="#05DCB2"/>
                                                                  <path d="M38.5011 52.5033C38.5011 42.8349 30.6725 35.0063 21.0042 35.0063V49.0088H14.0029V70.0125H20.9918C30.6602 70.0125 38.4887 62.1716 38.4887 52.5156L38.5011 52.5033Z" fill="#05DCB2"/>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_3022_102332">
                                                                      <rect width="49.0086" height="70" fill="white"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>

                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">BatchInvo</span>
                                                  </div>
                                                  <div title="Day Pays" v-if="hasLink('day-pay-requests')" @click="goToLinkHref('day-pay-requests')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="h-12 w-12" width="128" height="148" viewBox="0 0 128 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_4179_137300)" filter="url(#filter0_d_4179_137300)">
                                                                  <path opacity="0.5" d="M114.966 57.6243C120.141 51.5208 123.265 43.6315 123.265 35.0069C123.265 15.679 107.597 0.00552857 88.2689 0.00552857L46.2683 0V0.0110571C80.6118 0.110571 109.134 24.9394 114.966 57.6298V57.6243Z" fill="#FF00C7"/>
                                                                  <path opacity="0.5" d="M46.2683 140H88.2689V125.842C76.587 134.688 62.0413 139.951 46.2683 139.995V140.006V140Z" fill="#FF00C7"/>
                                                                  <path opacity="0.5" d="M88.2689 70.0084V125.841C105.148 113.065 116.061 92.8082 116.061 70.0028C116.061 65.779 115.685 61.6436 114.972 57.6299C108.553 65.1985 98.9778 70.0084 88.2745 70.0084H88.2689Z" fill="#FF00C7"/>
                                                                  <path opacity="0.5" d="M46.0638 0.00552857L4.06323 0V140H46.0638C46.1301 139.994 46.1965 139.989 46.2683 139.989V0.0110571C46.202 0.0110571 46.1357 0.0110571 46.0638 0.0110571V0.00552857Z" fill="#FF00C7"/>
                                                                  <path d="M114.966 57.624C109.134 24.9335 80.6118 0.104728 46.2683 0.0107422V139.994C62.0413 139.95 76.587 134.687 88.2689 125.841V70.008C98.9722 70.008 108.548 65.1981 114.966 57.6295V57.624Z" fill="#FF00C7"/>
                                                              </g>
                                                              <defs>
                                                                  <filter id="filter0_d_4179_137300" x="0.0632324" y="0" width="127.202" height="148" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                                      <feOffset dy="4"/>
                                                                      <feGaussianBlur stdDeviation="2"/>
                                                                      <feComposite in2="hardAlpha" operator="out"/>
                                                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4179_137300"/>
                                                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4179_137300" result="shape"/>
                                                                  </filter>
                                                                  <clipPath id="clip0_4179_137300">
                                                                      <rect width="119.202" height="140" fill="white" transform="translate(4.06323)"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>


                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">Day Pays</span>
                                                  </div>

                                                  <div title="Warehouse" v-if="hasLink('warehouse')" @click="goToLinkHref('warehouse')" class="text-center cursor-pointer">
                                                      <div class="bg-white dark:bg-v3-gray-900 h-16 w-16 rounded-lg flex justify-center items-center cursor-pointer mx-auto">
                                                          <svg class="w-12 h-12" width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g clip-path="url(#clip0_4695_29700)">
                                                                  <g clip-path="url(#clip1_4695_29700)">
                                                                      <path opacity="0.5" d="M90.8201 91.1299L97.9201 98.2199L98.2201 98.5199V91.1299H90.8201Z" fill="#0000FF"/>
                                                                      <path opacity="0.5" d="M98.22 48.61V-1H97.92V-0.87V38.09L84.04 24.21V48.61H98.22Z" fill="#0000FF"/>
                                                                      <path opacity="0.5" d="M41.52 48.61V38.08V38.09V-0.87V-1H-1V80.61V140.73L41.52 98.22V91.13V48.61Z" fill="#0000FF"/>
                                                                      <path opacity="0.5" d="M84.04 24.21V-1H41.52V38.08L69.72 9.89L84.04 24.21Z" fill="#0000FF"/>
                                                                      <path opacity="0.5" d="M98.22 140.73H140.43L98.22 98.5195V140.73Z" fill="#0000FF"/>
                                                                      <path opacity="0.5" d="M98.22 -1H140.44V80.61V140.73H140.74V91.13V48.61V-1H98.22Z" fill="#0000FF"/>
                                                                      <path opacity="0.5" d="M41.52 98.2195V140.73H84.04V91.1295H90.82L69.72 70.0195L41.52 98.2195Z" fill="#0000FF"/>
                                                                      <path d="M140.44 -1H98.22V48.61H84.04V24.21L69.72 9.89L41.52 38.08V48.61V91.13V98.22L69.72 70.02L90.82 91.13H98.22V98.52L140.43 140.73H140.44V80.61V-1Z" fill="#0000FF"/>
                                                                  </g>
                                                              </g>
                                                              <defs>
                                                                  <clipPath id="clip0_4695_29700">
                                                                      <rect width="140" height="140" fill="white" transform="translate(0.282227)"/>
                                                                  </clipPath>
                                                                  <clipPath id="clip1_4695_29700">
                                                                      <rect width="141.74" height="141.73" fill="white" transform="translate(-1 -1)"/>
                                                                  </clipPath>
                                                              </defs>
                                                          </svg>


                                                      </div>
                                                      <span class="text-v3-gray-900 dark:text-white">Warehouse</span>
                                                  </div>
                                              </div>
                                              <adverts v-if="allAdverts" :allAdverts="allAdverts"></adverts>

                                          </div>
                                      </div>
                                  </div>
                              </div>


                          </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </nav>
</template>
<script>
import vClickOutside from "v-click-outside";
import Notifications from "@/v3/components/dashboardWigets/Notifications.vue";
import DatePicker from "vuejs-datepicker";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import LazyList from "lazy-load-list/vue";
import Adverts from "@/v3/components/Adverts.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import SearchElement from "@/v3/components/headers/SearchElement.vue";

export default {
  name: "Header",
    components: {SearchElement, CollapsableHeader, Notifications, DatePicker, LazyList, Adverts},
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            showSearch: false,
            showApplications: false,
            showAccountActions: false,
            currentApp: 'HR',
            orderResources: null,
            orderOpen: false,
            rentalResources: null,
            rentalOpen: false,
            query: null,
            date: new Date(),
            allAdverts: null,
            notifications: null,
            notificationsTotal: 0,
            paymentVoucherResources: null,
            paymentVoucherOpen: false,
            basePeopleResources: null,
            basePeopleOpen: false,
            hqPeopleResources: null,
            hqPeopleOpen: false,
            projectsResources: null,
            projectsOpen: false,
            leaveRequestResources: null,
            leaveRequestsOpen: false,
            trackersResources: null,
            trackersOpen: false,
            qasResources: null,
            qasOpen: false,
            ossResources: null,
            ossOpen: false,
            hqProjectsResources: null,
            hqProjectsOpen: false
        }
    },
      props: {
        logo: {
          type: String,
          default:null
        },
        mode:{
            type: String,
            default:'light'
      },
          user:{
            type:Object,
              default: null
          },
          links:{
            type:Object,
              default:null
          },
          actions:{
            type:Object,
              default: null
          }
},
    computed:{
        initials() {
            if (this.user && this.user.name) {
                return this.user.name
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        searchAction(){
            if(!this.actions){
                return null;
            }
            return this.actions.filter(action => action.name === 'search-apps').first();
        },
        searchActionData(){
            if (!this.searchAction.fields) {
                return {};
            }
            var payload = {};
            this.searchAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload
        },
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        highlighted:function() {
            return {
                dates: [ // Highlight an array of dates
                    new Date(),
                ]
            }
        }
    },
    created(){
        let follow = Siren.Helper.follow;

        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(
                follow("dashboards")
            )
            .then(res => {
                console.log('got_dashboard');
                var properties = res.body.properties;
                this.allAdverts = properties.get('all_adverts');
            }, this);
            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(
                follow("notifications_without_read")
            ).then(res=> {
                let siren = res.body;
                this.notifications = siren.entities.filter(entity => entity.rels.contains('notification') && !entity.entity.properties.get('is_read'))
                this.notificationsTotal = siren.properties.get('total');
            });



    },
    methods:{
        isUrlStaging(){
            return process.env.VUE_APP_BASE_URL === 'https://test.companiesms.co.uk'
        },
        clickOutsideSearch(){
            if(this.showSearch){
                this.showSearch = false;
            }
        },
        showSearchClicked(){
            this.showSearch = true;
        },
        closeAccountActions(){
            if(this.showAccountActions === true) {
                 this.showAccountActions = false;
            }
        },
        showAccountActionsClicked(){
            this.showAccountActions = true;
        },
        signOut(){
            this.$emit('signOut');
        },
        getLinkHref(key){
            return this.getFrontendRouteWithoutHostFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        getLinkPath(key){
            let linkSplit = this.links.filter(link => link.rels.contains(key)).first().href.split('/api');
            let url = linkSplit[0];
            if(linkSplit.length === 2){
                url = linkSplit[1];
            }
            return url;
        },
        goToLinkHref(key){
            let linkSplit = this.links.filter(link => link.rels.contains(key)).first().href.split('/api');
            let url = linkSplit[0];
            if(linkSplit.length === 2){
                url = linkSplit[1];
            }
            if(this.$route.path === url.split('?')[0]){
                window.location.href=url;
            }
            this.$router.push(url);
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        canAccessHelpMenu(){
            return this.hasLink('cms-help-hub')
        },
        toggleMode(){
            this.$emit('toggleMode');

        },
        closeApplications(){
            this.showApplications = false;
        },
        showHrApps(){
            this.currentApp = 'HR';
        },
        goToHrDashboard(){
            this.$router.push('/hr-dash');
        },
        goToDashboard(){
            this.$router.push('/');
        },
        changeApps(app){
            this.currentApp = app;
        },
        goToProcurement(){
            this.$router.push('/orders');
        },
        applicationsLinkClicked(){
            this.closeAccountActions();
            this.showApplications = true;
        },
        goToVouchers(){
            this.$router.push('/payment-vouchers');
        },
        goToCosting(){
            this.$router.push('/costing');
        },
        getToHqDash(){
            this.$router.push('/hq-dashboard');
        },
        goToBatchInvoice(){
            this.$router.push('/batch-invoice');
        },
        goToQaDash(){
            this.$router.push('/qa-dash');
        },
        goToHqPage(){
            if(this.hasLink('admin.clients')){
                this.goToLinkHref('admin.clients')
            }
            else if(this.hasLink('admin.users')){
                this.$router.push('hq-people');
            }
            else if(this.hasLink('invoices')){
                this.goToLinkHref('invoices');
            }else if(this.hasLink('invoices-in')){
                this.goToLinkHref('invoices-in');
            }else if(this.hasLink('credit-notes')){
                this.goToLinkHref('credit-notes');
            }else if(this.hasLink('sages')){
                this.goToLinkHref('sages');
            }
        },
        goToQaPage(){
            if(this.hasLink('qa-trackers')){
                this.goToLinkHref('qa-trackers')
            }else if(this.hasLink('quality-assessments')){
                this.goToLinkHref('quality-assessments')
            }else if(this.hasLink('templates')){
                this.goToLinkHref('templates');
            }else if(this.hasLink('pre-checks')){
                this.goToLinkHref('pre-checks');
            }
        },
        goToHrPage(){
            if(this.hasLink('admin.client')){
                this.$router.push('/account');
            }else if(this.hasLink('holiday-requests')){
                this.$router.push('/holiday-requests');
            }else {
                this.$router.push('/holiday-requests');
            }

        },
        async search(){
            if(this.query.length > 3){
                await this.searchAction.perform({
                    'filter[query]': this.query
                }).then(async res => {
                    this.orderResources = res.body.entities.filter(entity => entity.rels.includes('order'));
                    this.rentalResources = res.body.entities.filter(entity => entity.rels.contains('rental'));
                    this.paymentVoucherResources = res.body.entities.filter(entity => entity.rels.contains('payment-voucher'));
                    this.basePeopleResources = res.body.entities.filter(entity => entity.rels.contains('base-people'));
                    this.hqPeopleResources = res.body.entities.filter(entity => entity.rels.contains('hq-people'));
                    this.projectsResources = res.body.entities.filter(entity => entity.rels.contains('project'));
                    this.leaveRequestResources = res.body.entities.filter(entity => entity.rels.contains('leave-request'));
                    this.trackersResources = res.body.entities.filter(entity => entity.rels.contains('qa-tracker'));
                    this.qasResources = res.body.entities.filter(entity => entity.rels.contains('qa'));
                    this.ossResources = res.body.entities.filter(entity => entity.rels.contains('oss-detail'));
                    this.hqProjectsResources = res.body.entities.filter(entity => entity.rels.contains('hq-project'));
                })
            }else{
                this.orderResources = null;
                this.rentalResources = null;
                this.paymentVoucherResources = null;
                this.basePeopleResources = null;
                this.hqPeopleResources = null;
                this.projectsResources = null;
                this.leaveRequestResources = null;
                this.trackersResources = null;
                this.qasResources = null;
                this.ossResources = null;
                this.hqProjectsResources = null;
            }
        },
        include () {
            return [document.querySelector('.included')]
        },
        navigateToItem(item){
            let selfLink = item.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        }
    }
}
</script>

<style scoped>
@media (max-height: 1000px){
    .zoom-container{
        zoom:0.75;
    }
}
</style>
